@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&family=GFS+Didot&family=Lato:wght@300;400;700;900&family=Lora:wght@400;500;600;700&family=Montserrat+Alternates:wght@400;500;600;700;800;900&family=Pacifico&family=Playball&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:wght@300;400;500;600;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Chomsky";
  src: url("./assets/Chomsky.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.Chomsky {
  font-family: "Chomsky", "serif";
}
:root {
  --primary: #016b3a;
  --secondary: #ffb840;
  --rk-colors-accentColor: #016b3a !important;
}
[data-rk] {
  --rk-colors-accentColor: #016b3a !important;
}
@layer components {
  .flex-c {
    @apply flex justify-center items-center text-center;
  }
  .abs-c {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute;
  }
  .btn {
    @apply py-3 px-12  rounded-xl font-semibold transition-all duration-300 ease-in-out leading-normal;
  }
  .btn-main {
    @apply bg-primary hover:bg-primary/80 text-white;
  }
  .btn-gr {
    @apply apply-gr-btn text-white;
  }
  .min-h-dscreen {
    @apply min-h-[100dvh];
  }
  .max-w-modal {
    @apply w-screen md:max-w-2xl;
  }
  .max-screen {
    @apply px-4 xl:px-0 max-w-screen-lg xl:max-w-[1200px] 2xl:max-w-[1400px] 3xl:max-w-[1800px]  mx-auto;
  }
  .td-300 {
    @apply transition-all duration-300 ease-in-out;
  }
}

body {
  font-family: "Dancing Script", cursive;
  font-family: "GFS Didot", serif;
  font-family: "Montserrat Alternates", sans-serif;
  font-family: "Pacifico", cursive;
  font-family: "Playball", cursive;
  font-family: "Playfair Display", serif;
  font-family: "Raleway", sans-serif;
  font-family: "Lato", sans-serif;
  font-family: "Lora", serif;
  font-family: "Roboto", sans-serif;

  @apply text-neutral-2;
  background-color: #f5f5f5;
}

.roboto {
  font-family: "Roboto", sans-serif;
}
.didot {
  font-family: "GFS Didot", serif;
}
.montserrat {
  font-family: "Montserrat", sans-serif;
}

button:disabled {
  cursor: not-allowed !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "GFS Didot", serif;
}

.playball {
  font-family: "Playball", cursive;
}

.lora {
  font-family: "Lora", serif;
}

.article-preview {
  max-height: 5em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.article-preview::after {
  content: "...";
}

.menu-animation {
  will-change: transform, opacity;
  contain: layout style;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.8s cubic-bezier(0.52, 0.16, 0.04, 1);
}
